import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Experience } from './components/Experience';
import { Papers } from './components/Papers';
import { Presentations } from './components/Presentations';
import { Contact } from './components/Contact';

import './custom.css'

export default class App extends Component {
	static displayName = App.name;

	render() {
		return (
			<Layout>
				<Route exact path='/' component={Home} />
				<Route path='/experience' component={Experience} />
				<Route path='/skills' component={Home} />
				<Route path='/papers' component={Papers} />
				<Route path='/presentations' component={Presentations} />
				<Route path='/contact' component={Contact} />
			</Layout>
		);
	}
}
