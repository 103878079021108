import React, { Component } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export class Experience extends Component {
	static displayName = Experience.name;

	render() {
		return (
			<VerticalTimeline lineColor={ 'var(--bs-dark)' }>
				<VerticalTimelineElement className="vertical-timeline-element--work"
					contentStyle={{ background: 'var(--bs-secondary)', color: 'var(--bs-dark)' }}
					contentArrowStyle={{ borderRight: '7px solid  var(--bs-dark)' }}
					date="2021-Present"
					iconStyle={{ background: 'var(--bs-secondary)', color: 'var(--bs-dark)' }}
				>
					<h3 className="vertical-timeline-element-title"><strong>Full-Stack Engineer &amp; Head of Mission Software</strong></h3>
					<h4 className="vertical-timeline-element-subtitle">Distributed Avionics Ltd.</h4>
					<p>Distributed Avionics specialise in high-reliability flight control solutions for UAS platforms.</p>
					<p>As a full-stack engineer I am responsible for delivering a cloud/network based mission management system which eradicates the need for multiple software installations and allowing users to access their aircraft on the go.</p>
					<p><b>Technologies:</b> C#, .Net Core, ASP.Net, React, Angular, AWS, Protobuf, gRPC, SignalR</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement className="vertical-timeline-element--work"
					contentStyle={{ background: 'var(--bs-secondary)', color: 'var(--bs-dark)' }}
					contentArrowStyle={{ borderRight: '7px solid  var(--bs-dark)' }}
					date="2020-2021"
					iconStyle={{ background: 'var(--bs-secondary)', color: 'var(--bs-dark)' }}
				>
					<h3><strong>Senior Engineer &amp; Acting Team Lead</strong></h3>
					<h4>Tekever Ltd.</h4>
					<p>
						Continued development of the applications below, with the addition of project management responsibilities.
						<ul>
							<li>Requirements traceability</li>
							<li>Scheduling</li>
							<li>Mentoring and oboarding</li>
							<li>Direct user support</li>
						</ul>
					</p>
					<p><b>Technologies:</b> C++, C#, .Net Framework, WinForms, ZMQ, Protobuf</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement className="vertical-timeline-element--work"
					contentStyle={{ background: 'var(--bs-secondary)', color: 'var(--bs-dark)' }}
					contentArrowStyle={{ borderRight: '7px solid  var(--bs-dark)' }}
					date="2018-2019"
					iconStyle={{ background: 'var(--bs-secondary)', color: 'var(--bs-dark)' }}
				>
					<h3><strong>Engineer</strong></h3>
					<h4>Tekever Ltd.</h4>
					<p>
						As a member of the Ground Software Systems team, I was responsible for developing and maintaining the various ground applications used across the company in aviation.
					</p>
					<p>
						Ground Control Station (GCS):
						<ul>
							<li>Desktop application designed to be used by power users and beginners alike</li>
							<li>Control all aspects of aircrafts flight and payload</li>
							<li>Input flight paths and mark points of interest through map, list, video or image</li>
							<li>Watch live video from the aircraft and customise overlays</li>
						</ul>
					</p>
					<p>
						Ground Hardware Controllers:
						<ul>
							<li>Embedded software written in C++</li>
							<li>Listens on configurable endpoints for user commands</li>
							<li>Commands multiple 3rd-Party hardware components to ensure a good link witht he aircraft from the ground</li>
						</ul>
					</p>
					<p><b>Technologies:</b> C++, C#, .Net Framework, WinForms, ZMQ, Protobuf</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement className="vertical-timeline-element--work"
					contentStyle={{ background: 'var(--bs-secondary)', color: 'var(--bs-dark)' }}
					contentArrowStyle={{ borderRight: '7px solid  var(--bs-dark)' }}
					date="2014-2018"
					iconStyle={{ background: 'var(--bs-secondary)', color: 'var(--bs-dark)' }}
				>
					<h3><strong>Demonstrator &amp; PhD Candidate</strong></h3>
					<h4>University of Southampton</h4>
					<p>
						As a PhD student I developed software to support my research and that of others.
					</p>
					<p>
						NanoMagMC:
						<ul>
							<li>Monte Carlo spin-lattice simulation software written in C++</li>
							<li>Optimised for and deployed on HPC systems such as ARCHER and Iridis</li>
							<li>Fully configurable shapes, structure and interction strengths</li>
							<li>Used to produce a number of <a href="publications">peer-reviewed articles</a></li>
						</ul>
					</p>
					<p>
						NanoMagView:
						<ul>
							<li>A pair program for NanoMagMC which visualises spin-lattices from output files</li>
							<li>Fully 3D interactable view of the lattice</li>
						</ul>
					</p>
					<p>
						hyMC:
						<ul>
							<li>An experimental library for a Hybrid Monte Carlo simulation engine</li>
							<li>A C++ backend library with a Python interface using Cython</li>
						</ul>
					</p>
					<p>
						I also worked as a demonstrator on a number of software modules:
						<ul>
							<li>Python for undergraduate engineers</li>
							<li>C++ for undergraduate and postgraduate engineers</li>
							<li>OpenMP, MPI and general principles of parallelism for postgraduates</li>
						</ul>
					</p>
					<p><b>Technologies:</b> Python, Julia, C++, OpenMP, MPI, HPC Systems, QT</p>
				</VerticalTimelineElement>
			</VerticalTimeline>
		);
	}
}