import React, { Component } from 'react';
import ReactCardFlipper from "react-card-flipper";
import dpg2018 from "../images/PresentationPreviews/dpg2018.png";
import intermag2017 from "../images/PresentationPreviews/intermag2017.png";
import julia from "../images/PresentationPreviews/julia.png";
import aim2016 from "../images/PresentationPreviews/aim2016.png";
import doctoralFestival from "../images/PresentationPreviews/doctoralFestival.png";

export class Presentations extends Component {
	static displayName = Presentations.name;

	render() {
		return (
			<div className="hover-landscape-wrapper">
				<a href={`${process.env.REACT_APP_PROXY_ADDRESS}/api/downloadfile?filename=presentations/DPG_Spring_Meeting_2018.pdf`} className="text-reset">
				<ReactCardFlipper
						behavior="hover"
						width="350px" height="262px"
						className="cardHost">
						<div className="card-landscape" style={{ backgroundImage: `url(${dpg2018})` }} />
						<div className="card-landscape">
							<p>DPG Spring Meeting 2018</p>
						</div>
					</ReactCardFlipper>
				</a>
				<a href={`${process.env.REACT_APP_PROXY_ADDRESS}/api/downloadfile?filename=presentations/Intermag2017.pdf`} className="text-reset">
					<ReactCardFlipper
						behavior="hover"
						width="350px" height="262px"
						className="cardHost">
						<div className="card-landscape" style={{ backgroundImage: `url(${intermag2017})` }} />
						<div className="card-landscape">
							<p>Intermag 2017</p>
						</div>
					</ReactCardFlipper>
				</a>
				<a href={`${process.env.REACT_APP_PROXY_ADDRESS}/api/downloadfile?filename=presentations/NGCM_Seminar.pdf`} className="text-reset">
					<ReactCardFlipper
						behavior="hover"
						width="350px" height="262px"
						className="cardHost">
						<div className="card-landscape" style={{ backgroundImage: `url(${julia})` }} />
						<div className="card-landscape">
							<p>NGCM Seminar: Why Julia?</p>
						</div>
					</ReactCardFlipper>
				</a>
				<a href={`${process.env.REACT_APP_PROXY_ADDRESS}/api/downloadfile?filename=presentations/JWatersAIM2016.pdf`} className="text-reset">
					<ReactCardFlipper
						behavior="hover"
						width="350px" height="262px"
						className="cardHost">
						<div className="card-landscape" style={{ backgroundImage: `url(${aim2016})` }} />
						<div className="card-landscape">
							<p>AIM 2016</p>
						</div>
					</ReactCardFlipper>
				</a>
				<a href={`${process.env.REACT_APP_PROXY_ADDRESS}/api/downloadfile?filename=presentations/JWatersDoctFest.pdf`} className="text-reset">
					<ReactCardFlipper
						behavior="hover"
						width="350px" height="262px"
						className="cardHost">
						<div className="card-landscape" style={{ backgroundImage: `url(${doctoralFestival})` }} />
						<div className="card-landscape">
							<p>Doctoral Festival</p>
						</div>
					</ReactCardFlipper>
				</a>
			</div>
		);
	}
}