import React, { Component } from 'react';
import ReactCardFlipper from "react-card-flipper";
import distributionConference from '../images/PublicationPreviews/distributionConference.png';
import distributionIdentification from '../images/PublicationPreviews/distributionIdentification.png';
import fept from '../images/PublicationPreviews/fept.png';
import magpy from '../images/PublicationPreviews/magpy.png';

export class Papers extends Component {
	static displayName = Papers.name;

	render() {
		return (
			<div className="hover-portrait-wrapper">
				<a href="https://doi.org/10.1103/PhysRevApplied.11.024028" className="text-reset">
					<ReactCardFlipper
						behavior="hover"
						width="330px" height="440px"
						className="cardHost">
						<div className="card-portrait" style={{ backgroundImage: `url(${fept})` }} />
						<div className="card-portrait">
							<p>Resolving Anomalies in the Critical Exponents of FePt Using Finite-Size Scaling in Magnetic Fields (2019)</p>
						</div>
					</ReactCardFlipper>
				</a>
				<a href="https://arxiv.org/abs/1801.06073" className="text-reset">
					<ReactCardFlipper
						behavior="hover"
						width="330px" height="440px"
						className="cardHost">
						<div className="card-portrait" style={{ backgroundImage: `url(${magpy})` }} />
						<div className="card-portrait">
							<p>Magpy: A C++ accelerated Python package for simulating magnetic nanoparticle stochastic dynamics (2018)</p>
						</div>
					</ReactCardFlipper>
				</a>
				<a href="https://doi.org/10.1088/1361-6463/aa7e38" className="text-reset">
					<ReactCardFlipper
						behavior="hover"
						width="330px" height="440px"
						className="cardHost">
						<div className="card-portrait" style={{ backgroundImage: `url(${distributionIdentification})` }} />
						<div className="card-portrait">
							<p>Identification of Curie temperature distributions in magnetic particulate systems (2017)</p>
						</div>
					</ReactCardFlipper>
				</a>
				<a href="https://doi.org/10.1109/INTMAG.2017.8007794" className="text-reset">
					<ReactCardFlipper
						behavior="hover"
						width="330px" height="440px"
						className="cardHost">
						<div className="card-portrait" style={{ backgroundImage: `url(${distributionConference})` }} />
						<div className="card-portrait">
							<p>Methodology for indentifying the Curie temperature distributions of magnetic granular systems (2014)</p>
						</div>
					</ReactCardFlipper>
				</a>
			</div>
		);
	}
}