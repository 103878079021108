import React, { Component } from 'react';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';

export class NavMenu extends Component {
	static displayName = NavMenu.name;

	constructor(props) {
		super(props);

		this.toggleNavbar = this.toggleNavbar.bind(this);
		this.state = {
			collapsed: true
		};
	}

	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}

	render() {
		return (
			<header>
				<Navbar className="navbar-expand-sm navbar-toggleable-sm bg-primary" dark>
					<Container>
						<NavbarBrand tag={Link} to="/">jonathonwaters.com</NavbarBrand>
						<NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
						<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
							<ul className="navbar-nav flex-grow">
								<NavItem>
									<NavLink tag={Link} to="/">Home</NavLink>
								</NavItem>
								<NavItem>
									<NavLink tag={Link} to="/experience">Experience</NavLink>
								</NavItem>
								{/*<NavItem>*/}
								{/*	<NavLink tag={Link} to="/skills">What I Can Bring</NavLink>*/}
								{/*</NavItem>*/}
								<UncontrolledDropdown nav inNavbar>
									<DropdownToggle nav caret>Publications</DropdownToggle>
									<DropdownMenu className="bg-primary" right>
										<DropdownItem>
											<NavLink tag={Link} to="/papers">Journal Articles</NavLink>
										</DropdownItem>
										<DropdownItem>
											<NavLink tag={Link} to="/presentations">Presentations</NavLink>
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
								<NavItem>
									<NavLink tag={Link} to="/contact">Contact</NavLink>
								</NavItem>
							</ul>
						</Collapse>
					</Container>
				</Navbar>
			</header>
		);
	}
}
