import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import me from "../images/me.png";

export class Home extends Component {
	static displayName = Home.name;

	render() {
		return (
			<div className="row align-items-center">
				<div className="col-md-6 col-sm-12 text-center" style={{ paddingTop: 15 }}>
					<img className="my-pic rounded" src={ me } />
				</div>
				<div className="col-md-6 col-sm-12" style={{ paddingTop: 15 }}>
					<h1>
						<strong>Hi, I'm Jonathon,</strong>
					</h1>

					<h2 style={{ paddingTop: 15 }}>
						a full-stack software engineer, based in Southampton, with over 8 years of experience working in <strong>academic</strong> and <strong>industrial</strong> environments.
					</h2>

					<h2 style={{ paddingTop: 15 }}>
						I can help you with all your <strong>front-end</strong>, <strong>back-end</strong> and <strong>project management</strong> needs.
					</h2>

					<h3 style={{ paddingTop: 15 }}>
						Check out my <Link to="experience">experience</Link> and <Link href="skills">what I can bring</Link> to you, then <Link href="contact">get in contact</Link>!
					</h3>
				</div>
			</div>

		);
	}
}
