import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

export class Contact extends Component {
	static displayName = Contact.name;

	state = {
		statusText: ""
	}

	constructor(props) {
		super(props);
		this.onFormSubmit.bind(this);
		this.sendEmail.bind(this);
		this.setResultLabelFailed.bind(this);
		this.setResultLabelSending.bind(this);
		this.setResultLabelSuccess.bind(this);
	}

	onFormSubmit = (e) => {
		e.preventDefault()
		let email = {};
		email["replyAddress"] = e.target.emailInput.value;
		email["content"] = e.target.messageInput.value;
		this.setResultLabelSending();
		this.sendEmail(email);
	}

	render() {
		return (
			<div>
				<p style={{ paddingTop: 15 }}>
					Get in contact with the form below if you are looking to hire me or with any ideas for collaboration.
				</p>
				<Form onSubmit={this.onFormSubmit}>
					<FormGroup style={{ paddingTop: 15 }}>
						<Label for="emailInput">Your Email:</Label>
						<Input type="email" id="emailInput" />
					</FormGroup>
					<FormGroup style={{ paddingTop: 15, paddingBottom: 15 }}>
						<Label for="messageInput">Your Message:</Label>
						<Input type="textarea" id="messageInput" />
					</FormGroup>
					<Button type="submit">Submit</Button>
					<FormText style={{ paddingLeft: 10 }}>{this.state.statusText}</FormText>
				</Form>
			</div>
		);
	}

	async sendEmail(email) {
		try {
			let response = await fetch(`${process.env.REACT_APP_PROXY_ADDRESS}/api/email/sendemail`, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json; chjarset=utf-8'
				},
				body: JSON.stringify(email)
			});

			if (response.ok) {
				this.setResultLabelSuccess();
			}
			else {
				this.setResultLabelFailed();
			}
		}
		catch (errors) {
			console.error("Failed to send");
			this.setResultLabelFailed();
		}
	}

	setResultLabelSuccess() {
		this.setState({ statusText: "Success"});
	}

	setResultLabelFailed() {
		this.setState({ statusText: "Failed" });
	}

	setResultLabelSending() {
		this.setState({ statusText: "Sending" });
	}
}